import React from "react";
import { DataGrid, GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from "@mui/x-data-grid";
import { ISort } from "../../interfaces/Sort";
import CustomPagination from "../pagination/Pagination";
import "./Table.css";
import { observer } from 'mobx-react';
import { Checkbox } from "@mui/material";

import AddCircleIcon from "../icons/AddCircleIcon";
import RemoveCircleIcon from "../icons/RemoveCircleIcon";

type Props = {
  rows: any[];
  columns: GridColDef[];
  handleSortModelChange?: (field : string, sort : string) => void;
  isLoading?: boolean;
  withCheckbox?: boolean;
  withAllCheckbox?: boolean;
  selectedRowsData?: GridRowId[];
  pageSize?: number;
  setPageSize?: (value: number) => void;
  page?: number;
  setPage?: (value: number) => void;
  totalRecordsInDB?: number;

	onRowUnselected?: (id : GridRowId) => void;
	onRowSelected?: (id : GridRowId) => void;
	setIsSelectTen?: (value: boolean) => void;
};

const disableSelectAllCheckboxCSS = {
  "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
    display: "none"
  }
};

function DataTable(props: Props) {
  const {
    rows,
    columns,
    handleSortModelChange,
    isLoading,
    withCheckbox = false,
    withAllCheckbox = true,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalRecordsInDB,
		selectedRowsData,
		onRowUnselected,
		onRowSelected,
  } = props;


  const handleSelectionModelChange = (selection: GridSelectionModel, details: GridCallbackDetails<any>) => {

		selectedRowsData?.forEach(id => {
			if(selection.includes(id) === false) {
				onRowUnselected && onRowUnselected(id);
			}
		})

		selection.forEach(id => {
			if(selectedRowsData?.includes(id) === false) {
				onRowSelected && onRowSelected(id);
			}
		})
  };

  return (
    <div className="table-container">
      <div className='table'>
				<DataGrid
          sx={!withAllCheckbox ? disableSelectAllCheckboxCSS : {}}
          sortingOrder={["desc", "asc"]}
          rows={rows}
          columns={columns}
          loading={isLoading}
          rowCount={pageSize}
					
					showCellRightBorder={false}
					showColumnRightBorder={false}
					scrollbarSize={0}
					
          components={{
            Pagination: () =>
              <CustomPagination
                className='custom-pagination'
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalRecordsInDB={totalRecordsInDB}
              />,
							NoRowsOverlay: () => {
								//insert no data graphics
								return <div></div>
							},
							BaseCheckbox : React.forwardRef((props : any, ref : any) => {
								return <Checkbox
									icon={<AddCircleIcon />}
									checkedIcon={<RemoveCircleIcon />}
									{...props}
									ref={ref}
									/>
							})
          }}
          paginationMode="server"
          sortingMode="server"
          onSortModelChange={(data) => {
            const { field, sort } = { ...data[0], sort: data[0].sort as string };
            handleSortModelChange && handleSortModelChange(field, sort);
          }}
          checkboxSelection={withCheckbox}
          disableSelectionOnClick
          disableColumnMenu={true}
          selectionModel={selectedRowsData}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </div>
    </div>
  );
}

export default observer(DataTable);
