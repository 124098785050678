interface IPopupItems {
    label:string;
    name:string;
    component:string;
    options?:any;
}


export const PopupItems: IPopupItems[] = [
    {
        label:"תאריך הזמנה",
        name:"orderDate",
        component:"date",
    },
    // {
    //     label:"מועדון",
    //     name:"club",
    //     component:"select",
    // },
    // {
    //     label:"סדרה",
    //     name:"series",
    //     component:"select",

    // }, 
    // {
    //     label:"תעריף כרטיס",
    //     name:"price",
    //     component:"price",
    // },
    {
        label:"תעריף כרטיס",
        name:"priceBand",
        component:"select",
    },
    {
        label:"איצטדיון",
        name:"stadium",
        component:"select",
    },
    {
        label:"איזור",
        name:"area",
        component:"select",
    }
]