import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import rootStores from "../../stores/index";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import logo from '../../assets/logos/eventim_mc_neg.svg';

import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { blue } from "@mui/material/colors";
import './NavBar.css';
import {MenuItems} from './constsMenu';
interface IMenuItems {
  path: string;
  label:string;
  name:string;
  hasSubMenu:boolean;
  menuKey:number;
  subMenuKey?:number;
  isSubMenu?:boolean;
}

// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const authStore = rootStores.AuthStore;
const navBarStore = rootStores.NavBarStore;
const ResponsiveAppBar = observer(() => {
  const { userName, logout } = authStore;
  const [selected, setSelected] = useState("ניהול הדפסות");
  const [subMenuKey, setSubMenuKey] = useState(0);
	const navigate = useNavigate();

  const handleClickComponent = (e: any, menuItem:IMenuItems) => {
		if(menuItem.hasSubMenu && menuItem.subMenuKey && subMenuKey===0){
			setSubMenuKey(menuItem.subMenuKey);
      setAnchorElNav(e.currentTarget);
    }
    else if(subMenuKey!==0 && !menuItem.isSubMenu) {//close sub menu
      setSubMenuKey(0)
    }
		
    else{
			navigate(menuItem.path);
      navBarStore.setSelectedTab(menuItem);
      setSelected(e.target.textContent);
    }
  };

  const openSubMenu = (subMenuKey:number) =>{    
    let subMenu = MenuItems.filter(item=>item.menuKey===subMenuKey);
    return (
      <Container maxWidth={false}>

          {subMenu.map((item:IMenuItems) => {
          return (
            <MenuItem key={item.name}
              onClick={(e)=>handleClickComponent(e,item)}
              sx={{
               backgroundColor:getBackgroundColor(item), 
                padding:"0 !important",
              "&.MuiMenuItem-root:hover":{
                backgroundColor:getHoverBackgroundColor(item)
              } }}>
              {
                !item.hasSubMenu ?
                  <Link style={{ textDecoration: "none" }} to={item.path}>
                    <Typography className="sub-menu-label">{item.label}</Typography>
                  </Link>
                  :
                  <Typography className="sub-menu-label">{item.label}</Typography>
              }
            </MenuItem>
          );
        })}
      </Container>

    )
  }
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserLogout = async () => {
    await logout();
  };

  const getBackgroundColor = (page:IMenuItems) => {
    if(selected===page.label && !page.hasSubMenu){
      return "#FFFFFF";
    }
    else if(page.hasSubMenu && page.subMenuKey===subMenuKey){
      return "rgba(255, 255, 255, 0.08)"
    }
    else if(page.isSubMenu){
      return;
    }
    else{
      return "#5C6BC0";
    }
  }

  const getHoverBackgroundColor = (page:IMenuItems) => {
    if(selected===page.label && !page.hasSubMenu){
      return "#f1f0ee"
    }
    else{
      return "rgba(255, 255, 255, 0.08)"
    }
  }

  return (
    <AppBar position="static" sx={{width:"20%", 
    backgroundColor:"#5C6BC0",
		maxWidth:"250px"
    }}>
      <div className="app-bar-container" >
        <Container maxWidth={false}>
          {/* <Toolbar disableGutters > */}

          <img src={logo} alt="Logo" className="logo" />

          <div
            className="menu-container"
          >
            {MenuItems
            .filter(item=>item.isSubMenu===false)
              .map((page) => (
                <MenuItem

								onClick={(e: any) => {
                  handleClickComponent(e,page);
                    }}

                  key={page.name}
                  // style={{backgroundColor:getBackgroundColor(page)}}
                sx={{ backgroundColor:getBackgroundColor(page),
                  "&.MuiMenuItem-root:hover":{
                  backgroundColor:getHoverBackgroundColor(page) }
                  }}
                >
                  <div className="box-header">
                   
                      <Typography
                        className="menu-label"
                  sx={{color: selected===page.label && !page.hasSubMenu ? "black" : "#FFFFFF"}}
                      >
                        {page.label}

                      </Typography>
                {page.hasSubMenu && <ArrowDropDownRoundedIcon/>}
                  </div>
                  {
                page.hasSubMenu && page.subMenuKey===subMenuKey &&
                    <div className="sub-menu-container">
                      {openSubMenu(subMenuKey)}
                    </div>
                  }
                </MenuItem>

              ))}
          </div>

          <Box className="user-details-container" sx={{ flexGrow: 0 }}>
            <Box onClick={handleOpenUserMenu} style={{display: "flex", cursor: "pointer", width:"100%", justifyContent: "flex-end"}}>
							<Typography className="user-name-side-bar">{userName} </Typography>

							<Tooltip title="Open settings" style={{marginRight:"20px"}}>
								<IconButton sx={{ p: 0 }}>
									<Avatar
										sx={{
											bgcolor: "white",
											color: blue[700],
											fontSize: "25px",
										}}
									>
										{userName.slice(0, 1).toUpperCase()}
									</Avatar>
								</IconButton>
							</Tooltip>

						</Box>

            <Menu
              sx={{ mb: "100px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              
							anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              
							keepMounted

              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
							
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Button onClick={handleUserLogout}>Logout</Button>
              </MenuItem>
            </Menu>
          </Box>
          {/* </Toolbar> */}
        </Container>
      </div>
    </AppBar>
  );
});

export default ResponsiveAppBar;