import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from ".";
import { ISort } from "../interfaces/Sort";
import { getAllRFIDTicketsNotPrinted } from "../fetchers/RFIDTickets";

class RFIDTicketsStore {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  @observable
  initialFilter: ISort = {
    field: "id",
    sort: "asc",
    page: 1,
    fromDate: null,
    toDate: null,
    stadium: null,
    area: null,
    clubPrefix: null,
    priceBand: null,
    searchPhrase: undefined
  };

  @observable
  filter: ISort = {
    field: 'id',
    sort: 'asc',
    page: 1,
    fromDate: null,
    toDate: null,
    stadium: null,
    area: null,
    clubPrefix: null,
    priceBand: null,
    searchPhrase: undefined
  };

  @observable
  club: any = null;

  @observable
  allAreasCodes: any = [];

  @observable
  allStadiumsCodes: any = [];

  @observable
  allClubsCodes: any = [];

  @observable
  allStadiumsNames: any = [];
  
  @observable
  stadiums: any = [];
  
  @observable
  areas: any = [];

  @observable
  clubs: any = [];
  
  @observable
  priceBands: any = [];
  
  @observable
  isTableUpdated = false;
  
	@observable
	rfidNotPrintedTickets : any[] = [];

	@observable
	isLoadingRFIDNotPrintedTickets : boolean = false;
  
	@observable
	totalRFIDNotPrintedTicketsInDB : number = 0;

  @action
  clearFilter = () => {
    this.setFilter({
      ...this.initialFilter,
      clubPrefix: this.filter.clubPrefix,
      searchPhrase: this.filter.searchPhrase
    });
  };

	@action
	setFilterField = (key: keyof ISort, value: any) => {
        const newSort: ISort = { ...this.filter };
    newSort[key] = value;

    this.setFilter(newSort);
  };

  @action
  setClub = (value: boolean) => {
    this.club = value;
  };

  @action
  setIsTableUpdated = (value: boolean) => {
    this.isTableUpdated = value;
  };

  @action
  setFilter = (value: any) => {
    this.filter = value;
  };

  @action
  setPriceBands = (value: any) => {
    this.priceBands = value;
  };

  @action
  setAllAreasCodes = (areas: any) => {
    this.allAreasCodes = areas.map((area: any) => area.areaCode);
  };

  @action
  setAllClubsCodes = (clubs: any) => {
    this.allClubsCodes = clubs.map((club: any) => club.clubPrefix);
  };

  @action
  setAllStadiumsCodes = (stadiums: any) => {
    this.allStadiumsCodes = stadiums.map((stadium: any) => stadium.stadiumCode);
  };
  @action
  setAllStadiumsNames = (stadiums: any) => {
    this.allStadiumsNames = stadiums.map((stadium: any) => stadium.name);
  };
  
  @action
  setClubs = (clubs: any) => {
    this.clubs = clubs
  };

  @action
  setStadiums = (stadiums: any) => {
    this.stadiums = stadiums
  };
  
  @action
  setAreas = (areas: any) => {
    this.areas = areas
  };

	@action
  setRFIDNotPrintedTickets = (tickets: any[]) => {
    this.rfidNotPrintedTickets = tickets;
  };

	@action
	setSetLoading(value : boolean) 
	{
		this.isLoadingRFIDNotPrintedTickets = value;
	}

	@action
	setNotPrintedTickets(tickets : any[], totalRecordsInDB : number) {
		this.rfidNotPrintedTickets = tickets;
		this.totalRFIDNotPrintedTicketsInDB = totalRecordsInDB;
	}

	@action
	fetchRFIDNotPrintedTickets = async (pageSize : number, isNotPrinted : boolean) => {
    if(this.filter.clubPrefix === null) {
      return;
    }
    
		this.setSetLoading(true);
    
		try	{
			const data = await getAllRFIDTicketsNotPrinted(this.filter, pageSize, isNotPrinted);
			this.setNotPrintedTickets(data?.tickets ?? [], data?.paginationInfo?.totalRecordsInDB ?? 0);
			this.setSetLoading(false);
		} catch(err) {
			this.setNotPrintedTickets([], 0);
			this.setSetLoading(false);
		}
	}

}

export default RFIDTicketsStore;
