import { Translation } from "../interfaces/Translation";

const en: Translation = {
    SHF_STADIUM_29: 'טרנר באר שבע',
    SHF_PRICEBAND_1001:  'מחיר מלא / הזמנה',
    SHF_PRICEBAND_1501: 'מחיר מוזל עם הצגת תעודה',
    SHF_PRICEBAND_1901: 'מחיר מוזל',
    SHF_AREA_34: 'שער 1',
    SHF_AREA_35: 'שער 2',
    SHF_AREA_36: 'שער 3',
    SHF_AREA_37: 'שער 5',
    SHF_AREA_38: 'שער 4',
    SHF_AREA_40: 'שער 6',
    SHF_AREA_41: 'שער 7',
    SHF_AREA_42: 'שער 8',
    SHF_AREA_43: 'שער 9',
    SHF_AREA_44: 'שער 10',
    SHF_AREA_45: 'כסף תחתון',
    SHF_AREA_46: 'כבוד A',
    SHF_AREA_47: 'כבוד B',
    SHF_AREA_48: 'עיתונאים',
    SHF_AREA_49: 'נכים מזרחי',
    SHF_AREA_50: 'בוקס',
    SHF_AREA_51: 'נכים A',
    SHF_AREA_52: 'נכים B',
    SHF_AREA_53: 'נכים סיעודי',
    SHF_AREA_54: 'כסף עליון',
};

export function valueFormatter(params: any): string {
    const { value } = params ?? ''
    if (value in en) return en[value]
    else return value
}

export function t(value: string): string {
    if (value in en) return en[value]
    else return value
}

