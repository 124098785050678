import { UseFormRegister } from "react-hook-form";
import { ClubFormField } from "../NewClubComponent";
import { Button, IconButton, InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material";
import { useState } from "react";
import { FileOpen, Visibility, VisibilityOff, Clear } from "@mui/icons-material";

const NewClubFormInput = ({
  field,
  register,
  resetField,
	isDirty,
}: {
    field: ClubFormField;
    register: UseFormRegister<any>;
    resetField: () => void;
		isDirty: boolean;
  }) => {

  const [showPassword, setShowPassword] = useState(false);
    
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const baseProps: TextFieldProps = {
    className: "new-club-form-table-cell",
    size: "small",
    style: { width: 250 },
    ...register(field.name, { required: true }),
		placeholder: field.label,
  };
  
  switch (field.type) {
    case "text":
      return <TextField {...baseProps} type="text"
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: isDirty ? "visible" : "hidden" }}
              onClick={resetField}
            >
              <Clear />
            </IconButton>
          ),
        }}
        sx={{
          "& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
        }}
      />;
		
		case "number":
			return <TextField {...baseProps} type="number" inputProps={{step:1}}
				autoComplete='off'
				InputProps={{
					endAdornment: (
						<IconButton
							sx={{ visibility: isDirty ? "visible" : "hidden" }}
							onClick={resetField}
						>
							<Clear />
						</IconButton>
					),
				}}
				sx={{
					"& .Mui-focused .MuiIconButton-root": { color: "primary.main" },
				}}
			/>;

    case "password":
      return (
        <TextField
          autoComplete='new-password'
          {...baseProps}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <>
                <IconButton
                  sx={{ visibility: isDirty ? "visible" : "hidden" }}
                  onClick={resetField}
                >
                  <Clear />
                </IconButton>
                {field.type === "password" ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ) : null}
              </>
            ),
          }}
        />
      );

    case "file": {
      const acceptTypes: string =
        //".csv, " +
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " + // Excel Files 97-2003 (.xls)
        "application/vnd.ms-excel"; // Excel Files 2007+

      return (
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          variant="outlined"
          style={{ height: "40px", width: "250px" }}
        >
          <Typography component="p">העלה קובץ</Typography>

          <FileOpen style={{ marginRight: "10px" }} />

          <input
            type="file"
            {...register(field.name, { required: true })}
            className="invisible-file-input"
            accept={acceptTypes}
          />
        </Button>
      );
    }
  }

  return <div>unsupported field type: {field.type}</div>;
};

export default NewClubFormInput;