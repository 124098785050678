import { UseFormRegister } from "react-hook-form";
import { ClubFormField } from "../NewClubComponent";
import { Box, Typography } from "@mui/material";
import Field from "./NewClubFormInput";

const errorTypeText: { [key: string]: string | undefined } = {
  required: "שדה חובה",
};

const NewClubFormRow = (props: {
  field: ClubFormField;
  register: UseFormRegister<any>;
	isDirty: boolean;
	resetField: () => void;
  error: { [key: string]: any } | undefined;
}) => {
  const { field, error, resetField } = props;

  return (
    <Box className="new-club-form-table-row">
      <Box
        width={180}
        display="flex"
        borderRadius={1}
        bgcolor="rgba(236, 239, 241, 0.5)"
        paddingX={2}
      >
        <Typography style={{ margin: "auto 0" }} component="p">
          {field.label}
        </Typography>
      </Box>

      <Box>
        <Field {...props} />
      </Box>

      {error && (
        <Box
          paddingX={2}
          display="flex"
          borderRadius={1}
          bgcolor="rgba(236, 239, 241, 0.5)"
        >
          <p style={{ margin: "auto 0", color: "#b71c1c" }}>
            <b>{errorTypeText[error.type]}</b>
          </p>
        </Box>
      )}
    </Box>
  );
};

export default NewClubFormRow;