import { GridColDef } from "@mui/x-data-grid";
import { valueFormatter } from "../../translation/en";
import { Tooltip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const dateFormat = (params: any) => moment(params.value).format("DD/MM/YYYY")

const statusCell = (params: any) => {
  const { errorCode } = params.row
  if(!errorCode) return <></>
  
  return errorCode !== "S" ?
    <Tooltip title={params.row.errorDesc}>
      <div className="status">
        <div className="text">{'שגיאה'}</div>
        <ErrorIcon color="inherit" />
      </div>
    </Tooltip>
    : <Tooltip title={params.row.errorDesc}>
      <div className="status success">
        <div className="text">{'הודפס'}</div>
        <CheckCircleIcon color="inherit" />
      </div>
    </Tooltip>
}

const renderErrorDesc = (params: any) => {
  return <Tooltip title={params.row.errorDesc}>
        <div className="text">{params.row.errorDesc}</div>
    </Tooltip>
}

export const columns: GridColDef[] = [
  // { field: "id", headerName: "מספר" },
  { field: "orderDate", headerName: "תאריך הזמנה", valueFormatter: dateFormat },
  { field: "orderNumber", headerName: "מספר הזמנה" },
  { field: "priceBandCode", headerName: "סוג מנוי", valueFormatter },
  { field: "stadiumCode", headerName: "איצטדיון", width: 150, valueFormatter },
  { field: "areaCode", headerName: "איזור", valueFormatter },
  { field: "cardNumber", headerName: "מספר כרטיס", width: 120 },
  { field: "barcode", headerName: "ברקוד", width: 220 },
  { field: "errorCode", headerName: "סטטוס", width: 100, renderCell: statusCell },
  { field: "errorDesc", headerName: "תיאור", flex: 1, renderCell: renderErrorDesc}
];
