import { Box, Button, Container, CssBaseline } from "@mui/material";

import { useForm } from "react-hook-form";
import "./NewClubComponent.css";
import NewClubFormRow from "./components/NewClubFormRow";
import { INewClubData, createNewClub } from "../../fetchers/newClub";

export type ClubFormField = {
  name: keyof INewClubData;
  label: string;
  type: React.InputHTMLAttributes<unknown>["type"];
	files?: any[] // if the type is 'file' will be set to selected files 
};

const fields: ClubFormField[] = [
  { name: "ClubName", type: "text", label: "שם מועדון" },
  { name: "VenueShortCode", type: "text", label: "קוד קצר מיקום" },
  { name: "PrinterCode", type: "text", label: "קוד מדפסת" },
  { name: "clubPrefix", type: "text", label: "קידומת מועדון" },
  { name: "StadiumName", type: "text", label: "שם אצטדיון" },
  { name: "StadiumCode", type: "text", label: "קוד אצטדיון" },
  { name: "rangeId", type: "number", label: "Range ID" },
  { name: "eventimURL", type: "text", label: "כתובת איוונטים" },
  { name: "eventimUserName", type: "text", label: "שם משתמש איוונטים" },
  { name: "eventimPassword", type: "password", label: "סיסמה איוונטים" },
  { name: "fortressURL", type: "text", label: "כתובת Fortress" },
  { name: "fortressUserName", type: "text", label: "שם משתמש Fortress" },
  { name: "fortressPassword", type: "text", label: "סיסמה Fortress" },
  { name: "fortressApiKey", type: "text", label: "API Key Fortress" },
  { name: "fortressAppId", type: "text", label: "AppID Fortress" },
  { name: "fortressAgencyCode", type: "text", label: "קוד סוכנות Fortress" },
  { name: "fortressPort", type: "number", label: "Fortress Port" },
  { name: "dtsURL", type: "text", label: "כתובת DTS" },
  { name: "dtsApiKey", type: "text", label: "API Key DTS" },
  { name: "prdHomeHame", type: "number", label: "PRD Home Game" },
  { name: "prdMemberCard", type: "number", label: "PRD Member Card" },
  // { name: "excelFile", type: "file", label: "קובץ Excel" },
];

// const defaultFieldValues = {
//   ClubName: "1",
//   VenueShortCode: "1",
//   PrinterCode: "1",
//   clubPrefix: "1",
//   StadiumName: "1",
//   StadiumCode: "1",
//   eventimURL: "1",
//   eventimUserName: "1",
//   eventimPassword: "1",
//   fortressURL: "https://eventim.kivunim7.co.il/FGB_WebApplication/Secure/test/API",
//   fortressAuth: "RXZlbnRpbVRlc3Q6RXYzbnQxbVQzc3Qh",
//   fortressApiKey: "CB097720-62F8-490D-BCC2-6F45CB50A5B8",
//   fortressAppId: "com.BeerSheva",
//   fortressAgencyCode: "BeerSheva",
//   excelFile: undefined,
// };

const defaultFieldValues = {
  clubName: "",
  venueShortCode: "",
  printerCode: "",
  clubPrefix: "",
  stadiumName: "",
  stadiumCode: "",
  eventimURL: "",
  eventimUserName: "",
  eventimPassword: "",
  fortressURL: "",
  fortressAuth: "",
  fortressApiKey: "",
  fortressAppId: "",
  fortressAgencyCode: "",
  excelFile: undefined,
};

const NewClubComponent = () => {
  const {
    handleSubmit,
    control,
    register,
    reset,
		resetField,
    formState: { errors, dirtyFields },
		getValues
  } = useForm();

  document.title = "eventim - מועדון חדש";

  const onSubmit = (data: any) => {
    // createNewClub(data as INewClubData);
  };

	const values = getValues();

  return (
    <Container component="main" maxWidth="xl" className="new-club-page-container">
      <CssBaseline />

      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="title">
          הוסף מועדון חדש
        </div>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
					flexWrap="wrap"
					alignContent="flex-start"
					columnGap="10px"
          width="100%"
					height="70vh"
          style={{ direction: "rtl" }}
        >
          {fields.map((field) => (
            <NewClubFormRow
              key={field.name}
              field={{...field, }}
							isDirty={dirtyFields[field.name]}
              register={register}
							resetField={() => resetField(field.name)}
              error={errors[field.name]}
            />
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="flex-end"
          gap="10px"
        >
          <Button variant="contained" type="submit">
            הוסף מועדון
          </Button>

          <Button variant="contained" onClick={(e) => reset(defaultFieldValues)}>
            התחל מחדש
          </Button>
        </Box>
      </form>

      <CssBaseline />
    </Container>
  );
};

export default NewClubComponent;
