import React from "react";
import rootStores from "../../stores/index";
import "./Header.css";
import print_icon from "../../assets/print_icon.svg";
import { Box, Button, TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Popover } from "@mui/material";
import FilterPopup from "./FilterPopup";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { GridRowId } from "@mui/x-data-grid";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;
const AuthStore = rootStores.AuthStore;

type Props = {
	handleFilter: () => void;
	setIsRightDialogOpen?: any;
	isRightDialogOpen?: boolean;
	onSelectTen?: () => void;
	selectedRows?: GridRowId[],
};

const count = (value: any): number => {
	if (value) return 1;
	else return 0;
}

function Header(props: Props) {

	const { setClub, filter, setFilterField } = RFIDTicketsStore
	const clubs = toJS(RFIDTicketsStore.clubs)
	const sortData = toJS(filter);
	const club = toJS(RFIDTicketsStore.club);

	const { handleFilter, setIsRightDialogOpen, isRightDialogOpen, onSelectTen, selectedRows } = props;
	const navBarStore = rootStores.NavBarStore;
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const onPrintIcon = () => {
		setIsRightDialogOpen(!isRightDialogOpen);
	};

	const onSearchFieldKeyDown = (ev: any) => {
		if (ev.key === "Enter") {
			handleFilter();
			ev.preventDefault();
		}
	};

	const advancedFilterApplyed: number = count(sortData.area) + count(sortData.priceBand) + count(sortData.fromDate) + count(sortData.toDate);

	const selectCount = 10 - (selectedRows?.length ?? 0);

	const isAdmin = AuthStore?.clubPrefix === "ADMIN";

	return (
		<div className="header-container">
			<div className="header-title">
				<div className="table-name">{navBarStore.selectedTab.label}</div>
				{!isRightDialogOpen && <div className="print-icon" onClick={onPrintIcon}>
					<img src={print_icon} alt="print icon" />
				</div>}
			</div>

			<div className="search-filter-container">
		
				<div style={{width : "100%", height: "40px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
					{isAdmin ? <Autocomplete
						size="small"
						style={{ width: "100%" }}

						options={clubs}

						getOptionLabel={(option: any) => option?.name}
						autoComplete
						disableClearable
						value={club ? club : null}
						// @ts-ignore
						renderInput={(params) => <TextField {...params} label="מועדון" />}
						isOptionEqualToValue={(option, value) => option?.clubPrefix === value?.clubPrefix}
						onChange={(event: any, newValue: any) => {
							console.log("\x1b[33m  file: Header.tsx:129  Header  newValue:", newValue)
							setFilterField("clubPrefix", newValue?.clubPrefix)
							setClub(newValue)
						}} />
					: <h2 style={{margin: 0}}>{clubs[0]?.name}</h2>
					}
				</div>
				

				<Box display={"flex"} flexDirection={"row-reverse"} width={"100%"} gap={"10px"}>
					<TextField
						size="small"
						sx={{ flex: 1 }}
						className="search-in-table"
						placeholder="חיפוש"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						onKeyDown={onSearchFieldKeyDown}
						onChange={(e) => setFilterField("searchPhrase", e.target.value)}
					/>

					<Button
						variant="outlined"
						onClick={(e) => handleFilter()}
					>
						חפש
					</Button>

					<Box className="filter-botton-container" style={{ position: "relative" }}>

						<Button
							variant="outlined"
							size="small"
							style={{ height: "100%" }}
							onClick={handleClick}
							endIcon={<FilterListRoundedIcon color="primary" />}
						>
							סינון
							{advancedFilterApplyed > 0 ? <div className={"filter-dot"}
								style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
								{/* we put the Typography in div because else it won't be centered properly*/}
								<div><Typography variant="caption" sx={{ color: 'white', fontSize: '10px' }}>{advancedFilterApplyed}</Typography></div>
							</div> : <></>}
						</Button>

						<Popover
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<FilterPopup
								open={open}
								handleClose={handleClose}
								onClickFilter={handleFilter}
								sortData={sortData}
								onFieldChanged={setFilterField}
							/>
							{/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
						</Popover>
					</Box>

					{onSelectTen &&
						<Button
							onClick={onSelectTen}
							variant="outlined"
							sx={{ maxHeight: "40px", whiteSpace: "nowrap", maxWidth: "90px" }}
							disabled={selectCount === 0}
						>
							{
								`${10 - selectCount}/${10} נבחר`
							}
						</Button>}


				</Box>

			</div>
		</div>
	);
}

export default observer(Header);
