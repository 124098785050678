import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";
import moment from "moment";

export const columns: GridColDef[] = [
  // { field: "id", headerName: "id", flex: 1 },
  { field: "errorType", headerName: "Error Type", flex: 1 },
  {
    field: "errorDetails",
    headerName: "Error Details",
    flex: 1,
    renderCell: (params: any) => (
      <Tooltip title={<h2>{params.value}</h2>}>
        <span className="table-cell-content">{params.value}</span>
      </Tooltip>
    ),
  },
  { field: "ticketNumber", headerName: "Ticket Number", flex: 1 },
  {
    field: "barcode",
    headerName: "Barcode",
    flex: 1,
  },
  {
    field: "errorSource",
    headerName: "Error Source",
    flex: 1,
  },
  {
    field: "memberId",
    headerName: "Member Id",
    flex: 1,
  },
  {
    field: "uniqueId",
    headerName: "Unique Id",
    flex: 1,
  },
  // {
  //   field: "createdAt",
  //   headerName: "Created At",
  //   type: "date",
  //   flex: 1,
  //   renderCell: (params: any) => (
  //     <span className="table-cell-content">
  //       {moment(params.value).format("DD/MM/YYYY HH:MM")}
  //     </span>
  //   ),
  // },
  // {
  //   field: "updatedAt",
  //   headerName: "Updated At",
  //   type: "date",
  //   flex: 1,
  //   renderCell: (params: any) => (
  //     <span className="table-cell-content">
  //       {moment(params.value).format("DD/MM/YYYY HH:MM")}
  //     </span>
  //   ),
  // },
];
