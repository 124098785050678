import apiClient from "../api/apiClient";
import { ERRORS_API } from "../api/consts";
import { ISort } from "../interfaces/Sort";

export const getErrors = async (page: number, pageSize: number, filter: string) => {
  try {
    const filterQr = filter ? `&searchPhrase=${filter}` : '';
    const res = await apiClient.get(`${ERRORS_API}?page=${page}&limit=${pageSize}${filterQr}`);

		if(res?.status === undefined) {
			return Promise.reject(res);
		}

		if(res.status !== 200) {
			return Promise.reject(res.statusText);
		}

    return Promise.resolve(res);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const orderBy = async ({ field, sort, page }: ISort) => {
  try {
    // const res: any = await apiClient.get(
    //   `${ERRORS_ORDERBY_API}?page=${page}&col=${field}&order=${sort.toUpperCase()}`
    // );
    // return Promise.resolve(res);
  } catch (error: any) {
    return error?.message;
  }
};
