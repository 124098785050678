import dayjs from "dayjs";
import apiClient from "../api/apiClient";
import { FORTRESS_API } from "../api/consts";
import { ISort, ISort2 } from "../interfaces/Sort";
import { removeNulls } from "../utils/helpers";

function _formatSortData(sortData: any, pageSize: number, isNotPrinted = true) {
  const { fromDate, toDate, priceBand, area } = sortData;

  const _sort: ISort2 = {
    dates: {
      fromDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : undefined,
      toDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : undefined
    },
    price: {
      fromPrice: sortData.fromPrice,
      toPrice: sortData.toPrice,
    },
    area: area ? area?.areaCode : undefined,
    stadium: sortData.stadium,
    priceBand: priceBand && priceBand.priceBandCode ? priceBand.priceBandCode : null,
    page: sortData.page,
    order: [
      [sortData.field, sortData.sort ? sortData.sort.toUpperCase() : "ASC"],
    ],
    showNullDts: sortData.showNullDts ? sortData.showNullDts : 1,
    limit: pageSize,
    distinct: sortData.distinct ? sortData.distinct : 1,
    reverseResults: isNotPrinted ? 1 : 0,
    clubPrefix: sortData.clubPrefix ? sortData.clubPrefix : '',
    searchPhrase: sortData.searchPhrase
  };

  const _sortWithNoNulls = removeNulls(_sort);

  return _sortWithNoNulls;
}

export const reprintRFIDTickets = async (ticketsToPrint: any[], club: any) => {//TODO type ReprintMemberExtendedReq
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/reprintMemberExtended`, {barcodes: ticketsToPrint, club});
    return res;
  } catch (error: any) {
    return error?.message;
  }
};

export const getAllRFIDTicketsNotPrinted = async (sortData: ISort, pageSize: number, isNotPrinted: boolean) => {
  try {  
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllRFIDTicketsNotPrinted`, _formatSortData(sortData, pageSize, isNotPrinted));
    return res.data.data;
  } catch (error: any) {
    console.log("getAllRFIDTicketsNotPrinted error", error);
		return Promise.reject(error);
  }
};

export const getAllRFIDTicketsNotPrintedTotals = async (sortData: ISort) => {
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllRFIDTicketsNotPrintedTotals`, sortData);
    return res.data.data;
  } catch (error: any) {
    return error?.message;
  }
};

// export const orderBy = async ({ field, sort, page }: ISort) => {
//   try {
//     const res: any = await apiClient.get(
//       `${FORTRESS_API}?page=${page}&col=${field}&order=${sort.toUpperCase()}`
//     );
//     return Promise.resolve(res);
//   } catch (error: any) {
//     return error?.message;
//   }
// };

export const getAllPriceBandsCodes = async () => {
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllPriceBandsCodes`);
    return res.data.data;
  } catch (error: any) {
    return error?.message;
  }
};

export const getAllAreaCodes = async () => {
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllAreaCodes`);
    return res.data.data;
  } catch (error: any) {
    return error?.message;
  }
};

export const getAllStadiumsCodes = async () => {
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllStadiumsCodes`);
    return res.data.data;
  } catch (error: any) {
    return error?.message;
  }
};

export const getAllClubsCodes = async () => {
  try {
    const res: any = await apiClient.post(`${FORTRESS_API}/getAllClubsCodes`);
    return res.data.data;
  } catch (error: any) {
    console.error(error?.message);
    return []
  }
};