interface IMenuItems {
    path: string;
    label: string;
    name: string;
    hasSubMenu: boolean;
    menuKey: number;
    subMenuKey?: number;
    isSubMenu?: boolean;
}

export const MenuItems: IMenuItems[] = [
    {
        label: "ניהול הדפסות",
        name: "RFIDTickets",
        path: "/",
        hasSubMenu: false,
        menuKey: 1,
        isSubMenu: false,
    },
    {
        path: "/printsArchive",
        label: "ארכיון הדפסות",
        name: "printsArchive",
        hasSubMenu: false,
        menuKey: 1,
        isSubMenu: false,

    },
    {
        path: "/Errors",
        label:"שגיאות",
        name:"errors",
        hasSubMenu:false,
        menuKey:1,
        isSubMenu:false,
    },
		{
			path: "/newClub",
			label:"מועדון חדש",
			name:"newClub",
			hasSubMenu:false,
			menuKey:1,
			isSubMenu:false,
		},
    // {
    //     label:"printers",
    //     name:"printers",
    //     hasSubMenu:false,
    //     menuKey:1,
    //     path: "/printers",
    //     isSubMenu:false,

    // },
    // {
    //     label: "מכירת כרטיס יחיד",
    //     name: "oneTicketSale",
    //     hasSubMenu: false,
    //     menuKey: 1,
    //     path: "/",
    //     isSubMenu: false,

    // },
    // {
    //     label: "מכירת כרטיס עונתי",
    //     name: "SeasonTicketSale",
    //     hasSubMenu: false,
    //     menuKey: 1,
    //     path: "/",
    //     isSubMenu: false,

    // },
    // {
    //     path: "/",
    //     label: "ממשק ניהול",
    //     name: "managementInterface",
    //     hasSubMenu: true,
    //     subMenuKey: 2,
    //     menuKey: 1,
    //     isSubMenu: false,

    // },
    // {
    //     path: "/",
    //     label: "תרחישים ותגובות",
    //     name: "ScenariosAndResponses",
    //     hasSubMenu: false,
    //     menuKey: 1,
    //     isSubMenu: false,
    // },
    // {
    //     path: "/",
    //     label: "ניהול הדפסות",
    //     name: "printManagement",
    //     hasSubMenu: false,
    //     isSubMenu: true,
    //     menuKey: 2
    // },

]